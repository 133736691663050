import { HomeIcon, InformationIcon, MarketplaceIcon, MessageIcon } from '@assets'

export const navItems = [
    { path: '/home', title: 'home', icon: <HomeIcon /> },
    { path: '/about', title: 'about', icon: <InformationIcon /> },
    { path: '/contact', title: 'contact', icon: <MessageIcon /> },
    { path: `${window.location.host}/app/marketplace`, title: 'marketplace', icon: <MarketplaceIcon /> },
]

export const menuItemLegal = [
    { path: '/privacy-policy', title: 'policy' },
    { path: '/terms-and-conditions', title: 'terms' },
    // { path: PATH_INFORMATION_GAME, title: 'game' },
]
