/* react */
import { FC, memo, PropsWithChildren, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
/* hooks */
import { useTranslation } from 'react-i18next'
/* utils */
import { classNames, randomKey } from '@utils'
/* types */
import { navItems } from '@types'
/* componentes */
import { DropDownProfile, NavLink } from '@components'
/* assets */
import { LogoTipo, SettingIcon } from '@assets'
import { MdKeyboardArrowDown } from 'react-icons/md'
/* styles */
import './index.scss'

const Navbar: FC<
    PropsWithChildren<{
        className?: string
        isNavbar?: boolean
        isBackground?: boolean
    }>
> = ({ className = '', isBackground = false }) => {
    const [t] = useTranslation('global')

    const lang = localStorage.getItem('language')!

    const navigate = useNavigate()

    const dropdownRef = useRef<HTMLDivElement>(null)

    const [isDrop, setIsDrop] = useState(false)

    const handleDropDownFocus = (state: boolean) => setIsDrop(!state)

    const handleClickOutsideDropdown = (e: any) => {
        if (isDrop && !dropdownRef.current?.contains(e.target as Node)) {
            setIsDrop(false)
        }
    }

    window.addEventListener('click', handleClickOutsideDropdown)

    return (
        <header className={classNames('navbar', isBackground ? 'navbar--background' : '', className)}>
            <div className="navbar__header">
                <i className={classNames('navbar__logo')} onClick={() => navigate('/')}>
                    <LogoTipo />
                </i>

                <nav>
                    <ul className="navbar__options">
                        {navItems.map(({ title, path, icon }) => (
                            <li {...randomKey()}>
                                <NavLink
                                    to={title == 'marketplace' ? path.concat(`?lang=${lang}`) : path}
                                    className="navbar__option"
                                    activeClassName="navbar__option--active"
                                    isTarget={title == 'marketplace' ? true : false}
                                >
                                    <i>{icon}</i>

                                    <span>{t(`navbar.${title}`)}</span>
                                </NavLink>
                            </li>
                        ))}

                        <li>
                            <a href={`${window.location.host}/app/login?lang=${lang}`} target='_blank' className="navbar__login">
                                {t('navbar.sign-in')}
                            </a>
                        </li>

                        <li>
                            <div ref={dropdownRef}>
                                <div className="navbar__profile" onClick={() => handleDropDownFocus(isDrop)}>
                                    <i className="navbar__profile__avatar">
                                        <SettingIcon />
                                    </i>

                                    <i className={classNames('navbar__profile__arrow', isDrop ? 'navbar__profile__arrow--rotate' : '')}>
                                        <MdKeyboardArrowDown />
                                    </i>
                                </div>
                                {isDrop && <DropDownProfile onClick={() => handleDropDownFocus(isDrop)} />}
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default memo(Navbar)
